export default{
    methods: {
        /*
            divId : 인쇄한 영역의 id
            direction : 페이지 방향 0: 가로(default), 1: 세로
        */
        async $print (divId, direction) {
            if(direction && direction == '1') direction = 'portrait' // 세로
            else  direction = 'landscape' // 가로
            var div = document.getElementById(divId)
            
            if(div){
              //인쇄할 영역의 HTML
              let prtHtml = await div.innerHTML

              // 스위치 값 셋팅
              for (const node of [...document.querySelectorAll(".c-switch-input")]) {
                var checkboxHtml = node.outerHTML;       
                if(node.checked) checkboxHtml = checkboxHtml.replace("class","checked class") 
                else checkboxHtml = checkboxHtml.replace("class","!checked class") 
                prtHtml = prtHtml.replace(node.outerHTML, checkboxHtml)              
              }

              // 화면에 datepicker가 존재할 경우, 인쇄할 영역의 datepicker에 값 셋팅      
              if(document.getElementsByName("date").length>0) 
                prtHtml = prtHtml.replace('name="date"','name="date" value="'+document.getElementsByName("date")[0].value+'"' )

              
              for (const node of [...document.getElementById(divId).querySelectorAll(".stsLabel")]) {
                var nodeHtml = node.outerHTML
                //if(node.options.legend)node.options.legend.labels.fontColor="#000000"
                var color = node.style.color
                node.style.color="#a2a2a2"
                prtHtml = prtHtml.replace(nodeHtml, node.outerHTML)
                node.style.color=color
              }

              //캔버스를 이미지화
              for (const node of [...document.getElementById(divId).querySelectorAll("canvas")]) {
                var dataURL = node.toDataURL()
                prtHtml = await prtHtml.replace(node.outerHTML, "<img src='"+dataURL+"' style='solid #d3d3d3;'>")
              }


              // HTML의 스타일 지정
              let stylesHtml = '';
              for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
              }
              stylesHtml += '<style type="text/css"> @page { size: A4 '+direction+'; margin:0; } </style>'
              stylesHtml += '<style type="text/css"> @media print,screen{ *{margin:0; padding:0; -webkit-box-sizing: border-box;  box-sizing: border-box;  -webkit-print-color-adjust: exact;print-color-adjust: exact;} .tooltip-old-inner { display: none; }} </style>'

              var printHtml = `<!DOCTYPE html>
              <html>
                <head>
                  ${stylesHtml}
                </head>
                <body>
                  ${prtHtml}
                </body>
              </html>`
              
              document.getElementById("container").hidden = true
              var div = document.createElement("div")
              div.innerHTML = printHtml
              document.body.append(div)

              // 인쇄하기
              setTimeout("window.print()", 300);
              //window.print()

              // 인쇄창 닫기 후 처리
              window.onafterprint=function(){
                document.getElementById("container").hidden = false
                document.body.removeChild(div)
              }

            }
            else{
              window.print()
            }
          },
    }
}