<!--참조 페이지 : https://daily-life-of-bsh.tistory.com/208 -->
<template>
  <ul class="pagination justify-content-center">

    <li class="page-item" v-if="first">
      <router-link :to="{}" class="page-link" aria-label="First"  @click.native="movePage(1)">
        <span aria-hidden="true">first</span>
      </router-link>
    </li>
    <li class="page-item" v-if="prev">
      <router-link :to="{}" class="page-link" aria-label="Previous" @click.native="movePage(startPageIndex - 1)">
        <span aria-hidden="true">&laquo;</span>
      </router-link>
    </li>

    <li v-for="index in endPageIndex-startPageIndex + 1 " :key="index" class="page-item" :class="{active:( (startPageIndex + index - 1) == currentPageIndex)}" >
      <router-link :to="{}" class="page-link"  @click.native="movePage(startPageIndex + index - 1)"> 
        {{ startPageIndex + index - 1 }}
      </router-link>
      <!-- <a class="page-link" href="javascript:movePage(' + i + ')">' + i + '</a> -->
    </li>

    <li class="page-item" v-if="next">
      <router-link :to="{}" class="page-link" aria-label="Next"  @click.native="movePage(endPageIndex + 1)">
        <span aria-hidden="true">&raquo;</span>
      </router-link>
    </li>

    <li class="page-item" v-if="last">
      <router-link :to="{}" class="page-link" aria-label="Last"  @click.native="movePage(pageCount)">
        <span aria-hidden="true">last</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "row",
  props: {  
      listRowCount: {
        type: Number,
        default: 10,
      },
      pageLinkCount: {
        type: Number,
        default: 2,
      },
  },
  data() {
    return {
      totalListItemCount: 0,
      currentPageIndex: 1,
      pageCount: 0,
      startPageIndex: 0,
      endPageIndex: 0,
      prev: false,
      next: false,
      first: false,
      last: false,

    };
  },
  methods: {
    movePage( param  ) {
      this.currentPageIndex = param;
      this.initUI();
      this.$parent.pageNum ? this.$parent.pageNum = this.currentPageIndex : this.$parent.$parent.pageNum = this.currentPageIndex
      if(this.$parent && typeof this.$parent.searchList === 'function'){
        this.$parent.searchList()
      }else{
        this.$parent.$parent.searchList()
      }
    },
    initUI(){
      this.pageCount = Math.ceil(this.totalListItemCount/this.listRowCount);

      if( (this.currentPageIndex % this.pageLinkCount) == 0 ){
        this.startPageIndex = ((this.currentPageIndex / this.pageLinkCount)-1)*this.pageLinkCount + 1
      }else{
        this.startPageIndex = Math.floor(this.currentPageIndex / this.pageLinkCount)*this.pageLinkCount + 1
      }

      if( (this.currentPageIndex % this.pageLinkCount) == 0 ){ //10, 20...맨마지막
        this.endPageIndex = ((this.currentPageIndex / this.pageLinkCount)-1)*this.pageLinkCount + this.pageLinkCount
      }else{
        this.endPageIndex =  Math.floor(this.currentPageIndex / this.pageLinkCount)*this.pageLinkCount + this.pageLinkCount;
      }

      if(this.endPageIndex > this.pageCount){
        this.endPageIndex = this.pageCount
      }

      if( this.currentPageIndex <= this.pageLinkCount ){
        this.prev = false;
      }else{
        this.prev = true;
      }

      if(this.endPageIndex >= this.pageCount){
        this.endPageIndex = this.pageCount;
        this.next = false;
      }else{
        this.next = true;
      }
      if(this.next){
        this.last=true
      }else{
        this.last=false
      }
      if(this.prev){
        this.first=true
      }else{
        this.first=false
      }
    },    
    reloadPagination(totalListItemCount) {  
      this.totalListItemCount = totalListItemCount
      this.currentPageIndex =this.$parent.pageNum || this.$parent.$parent.pageNum
      this.initUI();
    },
    setCurrentPageIndex(currentPageIndex){
      this.pageCount= 0
    }
  },
  watch:{
    currentPageIndex: function(){
      this.initUI();
    }
  },
  created() { },
  mounted(){ }
};
</script>