<template>
  <div>    
    <div class="row my-2 mx-0" >
        <div class="col-sm-8 form-inline p-0" >
            <template v-if="showRegBtn && $hasWritePermission(this.menuKey)">         
                <slot
                  name="showRegBtn"
                >       
                <CButton
                square
                size="sm"
                v-c-tooltip="showRegBtnTooltip?showRegBtnTooltip:'등록'"
                @click="regItems()"
                class="btn btn-primary btn-lft-margin"
                >
                <CIcon
                    :content="$options.icons.cilNoteAdd"
                />
                </CButton>
                </slot>
            </template>
            <template v-if="showModifyBtn && $hasWritePermission(this.menuKey)">     
                <slot
                  name="showModifyBtn"
                >                     
                <CButton
                square
                size="sm"
                v-c-tooltip="showModifyBtnTooltip?showModifyBtnTooltip:'수정'"
                @click="modifyItems()"
                class="btn btn-primary btn-lft-margin"
                >
                <CIcon
                    :content="$options.icons.cilPencil"
                />
                </CButton>
                </slot>
            </template>
            <template v-if="showDeleteBtn && $hasWritePermission(this.menuKey)">    
                <slot
                  name="showDeleteBtn"
                >                  
                <CButton
                square
                size="sm"
                v-c-tooltip="showDeleteBtnTooltip?showDeleteBtnTooltip:'삭제'"
                @click="deleteItems()"
                class="btn btn-primary btn-lft-margin"
                >
                <CIcon
                    :content="$options.icons.cilTrash"
                />
                </CButton>
                </slot>
            </template>   
            <template v-if=showDatePicker>               
                <date-picker v-model="searchDate" range style="width: 210px;" @change="changeDate()" class="ml-1" :clearable="false" ></date-picker>
            </template>             
            <slot name="showSearchEtc">      
            </slot>                
            <template v-if=showSearch>
                <CSelect
                    :options=comboboxData
                    :value.sync=searchType
                    @change="onSelect"
                    class="ml-1"
                />
                <input
                    v-if=!searchCode
                    class="form-control ml-1"
                    type="text"
                    aria-label="table filter input"
                    v-model="searchText1"
                    @keyup.enter="fnSearch"
                    
                >    
                <CSelect
                    v-if=searchCode
                    :options=searchCodeList
                    :value.sync=searchText1
                    @change="searchCodeChange"
                    class="ml-1"
                />       
                <CSelect
                    v-if='searchCode&&subCodeList&&subCodeList.length>0'
                    :options=searchSubCodeList
                    :value.sync=searchText2
                    @change="searchSubCodeChange"
                    class="ml-1"
                />
            </template>
            <template v-if=showSearch>
                <CButton v-if="!searchCode"
                    size="sm"
                    @click="fnSearch"
                    class="btn btn-primary btn-lft-margin"
                    v-c-tooltip="'검색'"
                >
                    <CIcon
                        :content="$options.icons.cilSearch"
                    />
                </CButton>  
            </template>            

            <slot name="clearBtn">
            <template v-if=showSearch|showDatePicker>
                <CButton v-if="clearBtn"
                    @click="clear"
                >
                    <CIcon
                        :content="$options.icons.cilReload"
                        style="color:#ffffff"
                    />
                </CButton>
            </template>
            </slot>
        </div>
         <div
            v-if="itemsPerPageSelect"
            class="col-sm-4 p-0"
            :class="{ 'offset-sm-6': !haveFilterOption  }"
        >
            <div class="form-inline justify-content-sm-end">
            <!-- <label class="mfe-2">{{paginationSelect.label}}</label> -->
            <select
                class="form-control"
                @change="paginationChange"
                aria-label="changes number of visible items"
            >
                <option value="" selected disabled hidden>
                {{perPageItems}}
                </option>
                <option
                v-for="(number, key) in paginationSelect.values"
                :val="number"
                :key="key"
                >
                {{number}}
                </option>
            </select>
            
            <template v-if=showExcelBtn>      
                <slot
                  name="showExcelBtn"
                >                            
                <CButton
                square
                size="sm"
                v-c-tooltip="showExcelBtnTooltip?showExcelBtnTooltip:'엑셀 다운로드'"
                @click="excelExport()"
                class="btn btn-primary btn-lft-margin"
                >
                <!-- <CIcon
                    :content="$options.icons.cilDescription"
                /> -->
                                        
                <img
                    src="@/assets/icons/IconExcelDown.svg"
                    width=19
                    height=23
                />
                </CButton>
                </slot>
            </template>

            
            <template v-if=showPrintBtn>      
                <slot
                  name="showPrintBtn"
                >                            
                <CButton
                square
                size="sm"
                v-c-tooltip="showPrintBtnTooltip?showPrintBtnTooltip:'프린트'"
                @click="$print('printArea')"
                class="btn btn-primary btn-lft-margin"
                >
                <CIcon
                    :content="$options.icons.cilPrint"
                />
                </CButton>
                </slot>
            </template>

            </div>
        </div>
    </div>
    <slot name="over-table"/>
    <div :class="this.isModal ? 'position-relative-max-width' : 'position-relative'+this.responsive ? 'table-responsive' :'' ">
    <!-- <div :class="`position-relative ${responsive ? 'table-responsive' : '' }`"> -->
        <table :class="tableClasses" style="width:100%">
        <thead>
          <slot name="thead-top"/>
          <tr>
            <template v-for="(name, index) in columnNames">
              <th
                @click="changeSort(rawColumnNames[index], index)"
                :class="[headerClass(index), sortingIconStyles]"
                :style="headerStyles(index)"
                :key="index"
              >
                <slot :name="`${rawColumnNames[index]}-header`">
                  <div v-if="!isCheckBox(`${rawColumnNames[index]}`)">{{name}}                      
                    <slot
                    v-if="isSortable(index)"
                    name="sorting-icon"
                    :state="getIconState(index)"
                    >
                    <CIcon
                        width="18"
                        :content="sortImage(index)"
                        :style="sortStyles(index)"
                        :aria-label="`change column: '${name}' sorting`"
                    />
                    </slot>
                  </div>
                  <div v-else>                    
                    <CInputCheckbox 
                        :custom="true"
                        :checked.sync="allCheck"
                        v-if="multiCheck"
                    />
                  </div>
                </slot>
              </th>
            </template>
          </tr>
        </thead>
        <tbody 
          :style="clickableRows ? 'cursor:pointer;': null" 
          class="position-relative"
        >
          <template v-for="(item, itemIndex) in currentItems" >
            <tr
              @click="rowClicked(item, itemIndex + firstItemIndex, $event)"
              :class="item._classes" 
              :tabindex="clickableRows ? 0 : null"
              :key="itemIndex"
            >
              <template v-for="(colName, index) in rawColumnNames">
                <template v-if="$scopedSlots[colName]">
                    <slot
                        :name="colName"
                        :item="item"
                        :index="itemIndex + firstItemIndex"
                        
                    />
                </template>
                <td
                  v-else
                  :class="cellClass(item, colName, index)"
                  :key="index"
                >
                    <div v-if="fields[index].key == 'check_box'"> 
                        <CInputCheckbox
                            :custom="true"
                            name="checkboxes"
                            :checked.sync="checkbox[itemIndex]"
                            @change="itemChecked(itemIndex, item[fields[index].mapping])"
                        />
                    </div>
                    <div v-else-if="fields[index].badgeYn && item[colName]"> 
                        <h5><CBadge :color="$renderer(fields[index].codeYn&&fields[index].code?fields[index].code:colName, 1, item[colName])">
                                <div v-if="fields[index].codeYn"> 
                                {{$renderer(fields[index].code?fields[index].code:colName, 0, item[colName])}}
                                </div>
                                <div v-else>
                                {{String(item[colName])}}
                                </div>
                            </CBadge></h5>
                    </div>
                    <div v-else-if="fields[index].switchYn">                        
                        <CSwitch
                            color="primary"
                            :checked="item[colName]=='1'?true:false"
                            labelOn="ON" 
                            labelOff="OFF"
                            disabled=true
                        />
                    </div>
                    <div v-else-if="fields[index].iconYn">   
                        <img
                            :src="$renderer(fields[index].code?fields[index].code:colName, 2, item[colName])"
                            width=23px
                            height=23px
                        />
                    </div>
                    <div v-else>
                        <div v-if="fields[index].codeYn"> 
                        {{$renderer(fields[index].code?fields[index].code:colName, 0, item[colName])}}
                        </div>
                        <div v-else>
                        {{convertDate(item, colName)}}
                        </div>
                    </div>
                </td>                
              </template>              
            </tr>
            <!-- <tr 
              v-if="$scopedSlots.details && striped"
              :key="'details-striped' + itemIndex"
              class="invisible"
            ></tr> -->
            <tr
              v-if="showDetails&&details.includes(itemIndex + firstItemIndex)"
              @click="rowClicked(item, itemIndex + firstItemIndex, $event, true)"
              class="p-0"
              style="border:none !important"
              :key="'details' + itemIndex"
            >
                <td
                    :colspan="colspan"
                    class="p-0"
                    style="border:none !important"
                >
                    <div :style="detailsStyles()">
                        <slot
                        name="show_details"
                        :item="item"
                        :index="itemIndex + firstItemIndex"
                        >      
                    </slot>    
                    </div>
                </td>
            </tr>   
          </template>
          <tr v-if="!currentItems && !loading">
            <td :colspan="colspan">
              <slot name="no-items-view">
                <div class="text-center my-5">
                  <h2>
                    {{ noItemsText }}
                    <CIcon
                      width="30"
                      :content="$options.icons.cilBan"
                      class="text-danger mb-2"
                    />
                  </h2>
                </div>
              </slot>
            </td>
          </tr>
        </tbody>
        </table>
        
        <slot name="loading" v-if="loading">            
            <loadingBar/>
        </slot>
    </div>

  </div>
</template>

<script>
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import printTools from '@/js/common/printUtil.js'
import { cilSortAscending, cilSortDescending, cilBan, cilFilterX, cilPencil, cilPlaylistAdd, cilTrash, cilDescription, cilReload, cilSearch,cilPrint, cilNoteAdd} from '@coreui/icons'
export default {
    name: 'list',
    mixins : [printTools],
    icons: { cilSortAscending, cilSortDescending, cilBan, cilFilterX, cilPencil, cilPlaylistAdd, cilTrash, cilDescription,cilReload, cilSearch,cilPrint, cilNoteAdd},
    components: {
        CIcon,
        DatePicker,
    },
    props: {
        comboboxData: { type: Array },
        items: Array,
        fields:  Array,    
        itemsPerPage: {
            type: Number,
            default: 10
        },
        sorter: [Boolean, Object],
        tableFilter: [Boolean, Object],
        noItemsView: Object,
        loading: Boolean,
        size: String,
        dark: Boolean,
        striped: Boolean,
        fixed: Boolean,
        hover: Boolean,
        border: Boolean,
        outlined: Boolean,
        showSearch: Boolean,
        showRegBtn: Boolean,
        showRegBtnTooltip: String,
        showModifyBtn: Boolean,
        showModifyBtnTooltip: String,
        showDeleteBtn: Boolean,
        showDeleteBtnTooltip: String,
        showExcelBtn: Boolean,
        showExcelBtnTooltip: String,
        showPrintBtn: Boolean,
        showPrintBtnTooltip: String,
        showDatePicker: Boolean,
        showDetails: Boolean,
        itemsPerPageSelect: Boolean,
        defaultSort:String,
        defaultSearchType: String,
        defaultSearchDate: Array,
        excel: Object,
        mustCheckedList: Array,  // 반드시 체크되어야하는 체크리스트 (check_box 필드에 매핑된 컬럼(value)으로 설정 )
        multiCheck: {
            type: Boolean,
            default: () => { return true }
        }, // 멀티체크 여부 (true면 멀티체크, false면 단일체크, 디폴트 true)
        menuKey: String,
        isModal: Boolean
    },
    data: function() {
        let searchType = '';
        if(typeof this.comboboxData !== 'undefined'){
            if(typeof this.defaultSearchType !== 'undefined'){
                searchType = this.defaultSearchType
            }else{
                searchType = this.comboboxData[0].value
            }
        }
        return {  
            searchType:searchType,
            searchText1:'',
            searchText2:'',
            searchParam: '',
            responsive: {
                type: Boolean,
                default: true
                },
            passedItems: this.items || [],        
            sorterState: {
                column: null,
                asc: true
            },
            clickableRows: Boolean,
            clearBtn:false,
            checked:false,
            perPageItems: this.itemsPerPage,
            details:[], // 로우 클릭시 나오는 상세정보
            searchDate: this.defaultSearchDate,
            searchCode: false,
            searchCodeList: [],
            searchSubCodeList: [], // 서브코드리스트(콤보박스에 셋팅할 리스트)
            subCodeList:[], // 서브코드리스트(전체 리스트)
            subCode: '', // 서브코드타입
            allCheck: false,
            checkedList: [], // 선택한 체크박스
            sortString: this.defaultSort
        }
    },      
    watch:{       
        items (val, oldVal) {
            // 체크박스 해제
            this.clearCheckBox() 
            // 필수 체크리스트 체크
            if(this.mustCheckedList) this.setCheckedList(this.mustCheckedList) 
            if (val && oldVal && this.objectsAreIdentical(val, oldVal)) {
                return
            }
            this.passedItems = val || []
        },
        allCheck:{
            immediate: true,
            handler (val) {
                if(typeof this.items !== 'undefined'){
                    // 체크박스 셋팅
                    this.checkbox = []
                    for(var i = 0 ; i < this.items.length ; i ++){
                        this.checkbox[i] = val
                        if(document.getElementsByName("checkboxes")[i])document.getElementsByName("checkboxes")[i].checked = val
                    }    
    
                    for(var i = 0 ; i < this.fields.length ; i ++){
                        if(this.fields[i].key == 'check_box'&&this.fields[i].mapping){
                            // checkedList에 값 채우기
                            this.checkedList = []
                            if(this.allCheck){
                                for(var j = 0 ; j < this.items.length ; j ++){
                                    this.checkedList.push(this.items[j][this.fields[i].mapping])
                                }   
                            }                     
                            break;
                        }
                    }
                    // 부모창에서 넘어온 체크리스트가 있으면 체크
                    if(!this.allCheck && this.mustCheckedList && this.multiCheck) this.setCheckedList()
                }
            }
        },
    },
    computed: {
        tableClasses () {
            return [
            'table',
            {
            [`table-${this.size}`]: this.size,
                'table-dark': this.dark,
                'table-striped': this.striped,
                'table-fixed': this.fixed,
                'table-hover': this.hover,
                'table-bordered': this.border,
                'border': this.outlined
                }
            ]
        },
        itemsDataColumns () {	
            return this.rawColumnNames.filter(name => {	
                return this.generatedColumnNames.includes(name)	
            })	
        },        
        currentItems () {            
            return this.paginatedItems
        },
        sortingIconStyles () {
            return {'position-relative pr-4' : this.sorter }
        },
        generatedColumnNames () {
            return Object.keys(this.passedItems[0] || {}).filter(el => el.charAt(0) !== '_')
        },
        rawColumnNames () {
            if (this.fields) {
                return this.fields.map(el => el.key || el)
            }
            return this.generatedColumnNames
        },
        columnNames () {
            if (this.fields) {
                return this.fields.map(f => {
                    return f.label !== undefined ? f.label : this.pretifyName(f.key || f)
                })
            }
            return this.rawColumnNames.map(el => this.pretifyName(el))
        },
        paginatedItems () {
            return this.items
        },
        noItemsText () {
            const customValues = this.noItemsView || {}
            return customValues.noItems || 'No items'
        },
        colspan () {
            return this.rawColumnNames.length
        },
        tableFilterData () {
            return {
                label: this.tableFilter.label || 'Filter:',
                placeholder: this.tableFilter.placeholder || 'type string...'
            }
        },
        firstItemIndex () {
            return (this.computedPage - 1) * this.perPageItems || 0
        },
        paginationSelect () {
            return {
                label: this.itemsPerPageSelect.label || 'Items per page:',
                values: this.itemsPerPageSelect.values || [5, 8, 10, 20, 50]
            }
        },
        haveFilterOption () {
            return true
            //return this.tableFilter || this.cleaner || this.$scopedSlots.cleaner
        },   
    },
    mounted(){
    },
    created(){
        this.onSelect()
    },
    methods: {
        headerClass (index) {
            const fields = this.fields
            return fields && fields[index]._classes ? fields[index]._classes : ''
        },
        isSortable (index) {
            return this.sorter && 
                (!this.fields || this.fields[index].sorter !== false) && 
                this.itemsDataColumns.includes(this.rawColumnNames[index])
        },
        headerStyles (index) {
            let style = 'vertical-align:middle;overflow:hidden;'
            if (this.isSortable(index)) {
                style += `cursor:pointer;`
            }
            if (this.fields && this.fields[index] && this.fields[index]._style) {
                style += this.fields[index]._style
            }
            return style
        },
        getIconState (index) {
            const direction = this.sorterState.asc ? 'asc' : 'desc'
            return this.rawColumnNames[index] === this.sorterState.column ? direction : 0
        },
        iconClasses (index) {  
            const state = this.getIconState(index)
            return [
                'icon-transition position-absolute arrow-position',
                {
                'transparent': !state,
                'rotate-icon': state === 'desc'
                }
            ]
        },
        sortStyles(index){
            let style = ''
            const state = this.getIconState(index)
            if(state) style+= (this.$store.state.darkMode?'color:#7267d3':'color:blue')
            return style
        },
        sortImage(index){
           let image = this.$options.icons.cilSortAscending
           const state = this.getIconState(index)
           if(state === 'desc') image = this.$options.icons.cilSortDescending
           return image
        },
        changeSort (column, index) {
            if (!this.isSortable(index)) {
                return
            }
            //if column changed or sort was descending change asc to true
            const state = this.sorterState
            const columnRepeated = state.column === column 
            if (!this.sorter || !this.sorter.resetable) {
                state.column = column 
            } else {
                state.column = columnRepeated && state.asc === false ? null : column
            }
            state.asc = !(columnRepeated && state.asc)
            this.$emit('update:sorter-value', this.sorterState)
            this.sortString = state.asc?state.column:"-"+state.column
            // this.$parent.sort ? this.$parent.sort = sortString : this.$parent.$parent.sort = sortString
            // alert(sortString)
            if(this.$parent && typeof this.$parent.searchList === 'function'){
                this.$parent.searchList()
            }else{
                this.$parent.$parent.searchList()
            }
        },
        pretifyName (name) {
            return name.replace(/[-_.]/g, ' ')
                .replace(/ +/g, ' ')
                .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
        },
        cellClass (item, colName, index) {
            let classes = []
            if (item._cellClasses && item._cellClasses[colName]) {
                classes.push(item._cellClasses[colName])
            }
            if (this.fields && this.fields[index]._classes) {
                classes.push(this.fields[index]._classes)
            }
            return classes
        },
        
        objectsAreIdentical (obj1, obj2) {
            return obj1.length === obj2.length && 
                    JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        fnSearch() { // search 버튼 클릭시            
            this.$parent.searchType ? this.$parent.searchType = this.searchType : this.$parent.$parent.searchType = this.searchType
            this.$parent.pageNum ? this.$parent.pageNum = 1 : this.$parent.$parent.pageNum = 1
            
            const regExp = /[ \{\}\[\]\/?,;:|\)*~`!^\-+┼<>\#$%\'\"\\\(\=]/gi;
            if(this.searchText1){
                if(regExp.test(this.searchText1)){
                    //값이 일치하면 문자를 삭제
                    alert('잘못된 입력 값 입니다.')
                    this.searchText1 = this.searchText1.replace(regExp, '');
                    return
                }
            }
            if(this.searchText2){
                if(regExp.test(this.searchText2)){
                    //값이 일치하면 문자를 삭제
                    alert('잘못된 입력 값 입니다.')
                    this.searchText2 = this.searchText2.replace(regExp, '');
                    return
                }
            }

            if(this.searchText1||this.searchText2) this.clearBtn =true
            else this.clearBtn = false
            if(this.$parent && typeof this.$parent.searchList === 'function'){
                this.$parent.searchList()
            }else{
                this.$parent.$parent.searchList()
            }             
        },
        async clear() { // clear버튼 클릭시
            this.searchText1=''
            this.searchText2=''
            this.searchSubCodeList = this.subCodeList  // 서브코드리스트를 전체 서브코드리스트로 셋팅
            this.clearBtn = false
            this.searchDate = this.defaultSearchDate   
            this.$parent.searchType ? this.$parent.searchType = this.searchType : this.$parent.$parent.searchType = this.searchType
            //this.$parent.searchDate = this.defaultSearchDate
            this.$parent.pageNum ? this.$parent.pageNum = 1 :this.$parent.$parent.pageNum =1
            this.$parent.sort ? this.$parent.sort =this.$parent.defaultSort : this.$parent.$parent.sort =this.$parent.$parent.defaultSort
            if(this.$parent && typeof this.$parent.searchList === 'function'){
                this.$parent.searchList()
            }else{
                this.$parent.$parent.searchList()
            }
        },
        rowClicked (item, index, e, detailsClick = false) {
            this.$emit(
                'row-clicked', item, index, this.getClickedColumnName(e, detailsClick), e
            )
            if(this.showDetails && this.getClickedColumnName(e, detailsClick) != 'show_buttons' 
                    &&this.getClickedColumnName(e, detailsClick) != 'check_box'){
                if(this.details.includes(index))    this.details.splice(this.details.indexOf(index), 1)
                else {
                    if(this.details.length > 0){
                        this.details.splice(-1, 1)
                    }//기존 row 닫히도록 수정
                    this.details.push(index) 
                    this.searchDetail(item, index)                    
                }    
            }
            
        },
        getClickedColumnName (e, detailsClick) {
            if (detailsClick) {
                return 'details'
            } else {
                const children = Array.from(e.target.closest('tr').children)
                const clickedCell = children.filter(child => child.contains(e.target))[0]
                return this.rawColumnNames[children.indexOf(clickedCell)]
            }
        },
        isCheckBox(data){
            return data=='check_box'
        },
        regItems(){
            this.$parent.regItems()
        },
        modifyItems(){
            this.$parent.modifyItems()
        },
        deleteItems(){
            this.$parent.deleteItems()
        },
        searchDetail(item, index){
            if(this.$parent.searchDetail) this.$parent.searchDetail(item, index)
            else this.$parent.$parent.searchDetail(item, index)
        },
        paginationChange (e) {            
            this.$parent.itemPerPage ? this.$parent.itemPerPage = e.target.value*= 1 : this.$parent.$parent.itemPerPage = e.target.value*= 1
            this.$parent.pageNum ? this.$parent.pageNum = 1 : this.$parent.$parent.pageNum = 1
            if(this.$parent && typeof this.$parent.searchList === 'function'){
                this.$parent.searchList()
            }else{
                this.$parent.$parent.searchList()
            }
        },
        clearCheckBox(){
            this.checked=false
            // 체크박스 초기화
            for(var i = 0 ; i < document.getElementsByName("checkboxes").length ; i ++){
                document.getElementsByName("checkboxes")[i].checked = false
            }           
            this.allCheck = false
            this.checkedList=[]
        },
        changeDate(){
            this.$parent.pageNum ? this.$parent.pageNum =1 : this.$parent.$parent.pageNum =1
            this.clearBtn = true
            if(this.$parent && typeof this.$parent.searchList === 'function'){
                this.$parent.searchList()
            }else{
                this.$parent.$parent.searchList()
            }            
        },
        // 파라미터 생성
        getParams(){
            // var sort=''
            // if(this.$parent.sort){
            //     sort = this.$parent.sort
            // }else if(this.$parent.defaultSort){
            //     sort = this.$parent.defaultSort
            // }else if (this.$parent.$parent.sort){
            //     sort = this.$parent.$parent.sort
            // }else{
            //     sort = this.$parent.$parent.defaultSort
            // }
            // sort=sortString
            var params = { 
                size: this.$parent.itemPerPage ? this.$parent.itemPerPage : this.$parent.$parent.itemPerPage, 
                offset: this.$parent.pageNum ? this.$parent.pageNum*this.$parent.itemPerPage - this.$parent.itemPerPage : this.$parent.$parent.pageNum*this.$parent.$parent.itemPerPage - this.$parent.$parent.itemPerPage,
                sort:this.sortString,
            }
      
            if(this.searchDate || this.searchText1 || this.searchText2){       
                if(this.showDatePicker && this.searchDate){
                    if(this.searchDate[0] && this.searchDate[1]){
                        params["startDate"]=this.$date(this.searchDate[0]).format('YYYY-MM-DD')
                        params["endDate"]=this.$date(this.searchDate[1]).format('YYYY-MM-DD')
                    }
                }
                if(this.showSearch && (this.searchText1||this.searchText2)){  
                    if(this.subCodeList&&this.subCodeList.length>0 ){
                        params[this.searchType]=this.searchText1
                        params[this.subCode]=this.searchText2
                    } 
                    else{
                        params[this.searchType]=this.searchText1
                    }
                }
            }
            return params
        },
        async onSelect(){                    
            this.searchText1 = ''    
            this.searchText2 = ''
            for(var index in this.comboboxData){
                if(this.comboboxData[index].value == this.searchType){
                    if(this.comboboxData[index].codeYn){
                        this.searchCode = true
                        this.searchCodeList=[]
                        //this.searchCodeList.push({label:'전체',value:''})
                        this.searchCodeList = this.searchCodeList.concat(this.$getCodeList(this.comboboxData[index].value))
                        if(this.comboboxData[index].subCode){
                            this.subCode = this.comboboxData[index].subCode
                            this.subCodeList=[]
                            //this.subCodeList.push({label:'전체',value:''})
                            this.subCodeList = this.subCodeList.concat(this.$getCodeList(this.comboboxData[index].subCode))
                            this.searchSubCodeList = this.subCodeList
                        }
                    }
                    else this.searchCode = false
                }
            }
            this.clearBtn = false
        },
        searchCodeChange(e){
            this.searchSubCodeList = []
            // 서브 SELECT BOX가 존재할 때
            if(this.subCodeList&&this.subCodeList.length>0 ){
                this.searchText2 =''
                if(e.target.value == '') this.searchSubCodeList = this.subCodeList
                else{
                    //this.searchSubCodeList.push({label:'전체',value:''})
                    for(var i in this.subCodeList){
                        if(this.subCodeList[i].parent == e.target.value){
                            this.searchSubCodeList.push(this.subCodeList[i])
                        }
                    }
                }
            }
            this.$parent.pageNum=1
            this.fnSearch()
        },        
        searchSubCodeChange(e){
            this.$parent.pageNum=1
            this.fnSearch()
        },
        // 체크리스트 리턴 => (5)[true,false,true,false,false]
        getAllCheckList(){ 
            return this.checkbox
        },
        getCheckedList(){
            return this.checkedList
        },
        // 엑셀 다운로드 (List컴포넌트에 showExcelBtn 값 넘겨줘야함)
        excelExport(){ // 대용량 데이터 대비하여 서버에서 엑셀처리 필요            
            if(this.items && this.items.length>0)   this.$excelDownload(this.getExcelParam())
            else alert("데이터가 없습니다.")
        },
        // 파라미터 생성
        getExcelParam(){   
            var params = { 
                sort:this.sortString,
                columns: this.$getExcelColumns(this.fields),  // 엑셀에 보여줄 컬럼
                fileNm:this.excel.excelFileNm ,       // 파일명    
                excelType:this.excel.excelType,       // 엑셀타입 (0: 검증로그, 1: 감사로그, 2: 앱 목록, 3: 앱정책감사로그, 4: 권한그룹 목록, 5: 관리자 목록)
            }
            if(this.excel.excelParams){
                for(var key in this.excel.excelParams){
                    params[key]=this.excel.excelParams[key]
                }
            }
            if(this.searchDate || this.searchText1 || this.searchText2){       
                if(this.showDatePicker && this.searchDate){
                    if(this.searchDate[0] && this.searchDate[1]){
                        params["startDate"]=this.$date(this.searchDate[0]).format('YYYY-MM-DD')
                        params["endDate"]=this.$date(this.searchDate[1]).format('YYYY-MM-DD')
                    }
                }
                if(this.showSearch && (this.searchText1||this.searchText2)){  
                    if(this.subCodeList&&this.subCodeList.length>0 ){
                        params[this.searchType]=this.searchText1
                        params[this.subCode]=this.searchText2
                    } 
                    else{
                        params[this.searchType]=this.searchText1
                    }
                }
            }
            return params
        },        
        detailsStyles(){
            let style = ''
            style+='border: 1px solid #ced2d8; overflow: auto; overflow-x:hidden; ' // 테두리 및 높이
            style+='margin-top:10px;margin-right:10px;margin-bottom:10px;margin-left:10px;' // 바깥 패딩
            style+='padding-top:10px;padding-right:30px;padding-bottom:10px;padding-left:30px;' // 안쪽 패딩
            return style
        },
        convertDate(item, colName){
            if(item[colName]==null) return ""

            if(colName.indexOf('Date')>0 && item[colName]){
                return this.$convertDate(item[colName])
            }
            else    
                return String(item[colName])
        },     
        itemChecked(index, value){
            if(this.checkbox[index]){
                if(!this.multiCheck){ 
                    this.clearCheckBox()
                    document.getElementsByName("checkboxes")[index].checked = this.checkbox[index]
                }
                this.checkedList.push(value)      
            }           
            else{
                const idx = this.checkedList.indexOf(value)
                if (idx > -1) this.checkedList.splice(idx, 1)
            }  
        },
        // 넘어온 값으로 체크
        setCheckedList(checkedList){
            if(checkedList) this.mustCheckedList = checkedList
            else checkedList = this.mustCheckedList

            this.checkedList = []
            // check_box의 매핑된 컬럼(value) 
            let column = ''
            for(var i = 0 ; i < this.fields.length ; i ++){
                if(this.fields[i].key == 'check_box'){
                    column = this.fields[i].mapping
                    break
                }
            }
            for(var i = 0 ; i < this.items.length ; i++){
                if(checkedList.includes(this.items[i][column])){
                    this.checkbox[i] = true
                    this.checkedList.push(this.items[i][column])
                    if(document.getElementsByName("checkboxes")[i])document.getElementsByName("checkboxes")[i].checked = true
                }
            }        
        }
    }
};
</script>

<style></style>